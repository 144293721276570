<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.contact_data') }}</span>
      </div>
    </b-card-header>
    <b-card-body v-if="elementData.contacts && resourcesElementName">
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="getContacts()"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(resourcesElementName + '.search.not_found')"
      >
        <!-- column: name -->
        <template #cell(data)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ data.item.data }}
          </b-badge>
        </template>
        <!-- Column: relationship -->
        <template #cell(relationship_id)="data">
          {{ resolveRelationshipMessage(data.item.relationship_id) }}
        </template>
        <!-- Column: TYPE -->
        <template #cell(type_id)="data">
          {{ resolveTypeMessage(data.item.type_id) }}
        </template>
        <!-- Column: key -->
        <template #cell(status)="data">
          <h2 class="inline">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.status)}`"
              class="text-capitalize mr-0 mt-50"
            >
              {{ resolveStatusMessage(data.item.status) }}
            </b-badge>
          </h2>
        </template>
        <!-- Column: Status -->
        <!-- Column: actions -->

      </b-table>
      <div>
        <b-modal
          id="modal-2"
          size="lg"
          hide-footer
          :title=" newContact ? $t(resourcesElementName + '.object.new_title') : $t(resourcesElementName + '.object.edit_title')"
        >
          <template>
            <contact-edit-tab
              :new-element="newContact"
              :element-data="contactData"
              :contact-type-list="contactTypeList"
              :department-list="departmentList"
              :city-list="cityList"
              :relationship-list="relationshipList"
              :population-zone-list="populationZoneList"
              :is-patient="isPatient"
              class="mt-2 pt-75"
            />
          </template>
        </b-modal>
      </div>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BTable, BModal,
} from 'bootstrap-vue'
import { required } from '@validations'
import ContactEditTab from './EditContact.vue'

export default {
  components: {
    ContactEditTab, BModal, BTable, BCard, BBadge, BCardHeader, BCardBody,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    cityList: {
      type: Array,
      required: true,
    },
    onlyActived: {
      type: Boolean,
      default: false,
    },
    relationshipList: {
      type: Array,
      required: false,
    },
    isPatient: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    populationZoneList: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      tableColumns: [
      ],
      resourcesElementName: '',
      required,
      contactData: {},
      newContact: true,
    }
  },
  beforeDestroy() {
    this.$root.$off('save-contact')
  },
  mounted() {
    this.$root.$on('save-contact', contactData => {
      const index = this.elementData.contacts.findIndex(element => element.id == contactData.id)
      if (index < 0) this.elementData.contacts.push(contactData)
    })
    this.resourcesElementName = 'contact'
    if (this.isPatient) {
      this.tableColumns = [
        { key: 'relationship_id', label: this.$t(`${this.resourcesElementName}.object.relationship`), sortable: true },
        { key: 'type_id', label: this.$t(`${this.resourcesElementName}.object.type`), sortable: true },
        { key: 'data', label: this.$t(`${this.resourcesElementName}.object.data`), sortable: true },
        { key: 'status', label: this.$t(`${this.resourcesElementName}.object.status`), sortable: true },
      ]
    } else {
      this.tableColumns = [
        { key: 'type_id', label: this.$t(`${this.resourcesElementName}.object.type`), sortable: true },
        { key: 'data', label: this.$t(`${this.resourcesElementName}.object.data`), sortable: true },
        { key: 'status', label: this.$t(`${this.resourcesElementName}.object.status`), sortable: true },
      ]
    }
  },
  methods: {
    getContacts() {
      if (this.onlyActived) {
        return this.elementData.contacts.filter(element => element.status)
      }
      return this.elementData.contacts
    },
    get_edit_button_id(key) {
      return `edit_button${key}`
    },
    get_deactivate_button_id(key) {
      return `deactivate_button${key}`
    },
    get_reactivate_button_id(key) {
      return `reactivate_button${key}`
    },
    editContact(item) {
      this.contactData = item
      this.newContact = false
      this.$root.$emit('bv::show::modal', 'modal-2')
    },
    addContact() {
      this.contactData = { id: Math.floor(Math.random() * 100) * -1, status: 1 }
      this.newContact = true
      this.$root.$emit('bv::show::modal', 'modal-2')
    },
    deactivateContact(id) {
      const element = this.elementData.contacts.find(element => element.id == id)
      const index = this.elementData.contacts.indexOf(element)
      element.status = 0
      this.elementData.contacts.splice(index, 1, element)
    },
    reactivateContact(id) {
      const element = this.elementData.contacts.find(element => element.id == id)
      const index = this.elementData.contacts.indexOf(element)
      element.status = 1
      this.elementData.contacts.splice(index, 1, element)
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-2')
    },
    resolveStatusVariant(status) {
      if (status > 0) return 'success'
      return 'warning'
    },
    resolveStatusMessage(status) {
      if (status > 0) return this.$t(`${this.resourcesElementName}.statusValue.active`)
      return this.$t(`${this.resourcesElementName}.statusValue.inactive`)
    },
    resolveTypeMessage(type) {
      if (type) {
        return this.contactTypeList.find(element => type === element.id).value
      }
      return ''
    },
    resolveRelationshipMessage(relationship) {
      if (relationship) {
        return this.relationshipList.find(element => relationship === element.id).value
      }
      return ''
    },
  },
}
</script>

<style>

</style>
