<template>
  <b-form
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Datos del destinatario</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="identity"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t('case.object.patient_identity') }}</span>
                <b-form-input
                  id="identity"
                  v-model="elementData.identity"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="patient_name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t('case.object.patient_name') }}</span>
                <b-form-input
                  id="patient_name"
                  v-model="patient_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="birthdate"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t('patient.object.birthdate') }}</span>
                <b-form-input
                  id="birthdate"
                  v-model="birthdate"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
              xl="2"
            >
              <b-form-group
                label-for="age"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t('patient.object.age') }}</span>
                <b-form-input
                  id="age"
                  v-model="age"
                  disabled
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <template v-if="elementData">
                <contacts-edit-tab
                  :new-element="false"
                  :element-data="elementData"
                  :contact-type-list="contactTypeList"
                  :department-list="departmentList"
                  :city-list="cityList"
                  :relationship-list="relationshipList"
                  :population-zone-list="populationZoneList"
                  :is-patient="true"
                  class="mt-2 pt-75"
                />
              </template>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

    </validation-observer>
  </b-form>

</template>

<script>
import {
  BFormTextarea, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import ContactsEditTab from '@/views/core/contact/ViewContacts.vue'
import moment from 'moment'

export default {
  components: {
    ContactsEditTab, BFormTextarea, DatePicker, BFormCheckbox, BCard, BButton, BRow, BCol, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    cityList: {
      type: Array,
      required: true,
    },
    relationshipList: {
      type: Array,
      required: true,
    },
    populationZoneList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resourcesElementName: 'patient',
      vigence_date_render: '',
      required,
    }
  },
  computed: {
    patient_name: {
      get() {
        return `${this.elementData.first_name} ${this.elementData.second_name} ${this.elementData.first_surname} ${this.elementData.second_surname}`
      },
    },
    birthdate: {
      get() {
        return moment(this.elementData.birthdate, 'DD/MM/YYYY').format('DD/MM/YYYY')
      },
    },
    age: {
      get() {
        if (this.elementData.birthdate) {
          let date = this.elementData.birthdate
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return moment().diff(date, 'years')
        }
        return null
      },
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          this.$parent.save(this.elementData)
        }
      })
    },
  },
}
</script>

<style>

</style>
