<template>
  <b-form
    v-if="receptionList"
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <patient-edit-card
        :element-data="elementData.case.patient"
        :contact-type-list="contactTypeList"
        :department-list="departmentList"
        :city-list="cityList"
        :relationship-list="relationshipList"
        :population-zone-list="populationZoneList"
      />
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Datos de entrega</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="12"
              xl="12"
            >
              <b-form-group
                label-for="address"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.address') }}</span>
                <b-form-input
                  id="address"
                  v-model="elementData.address"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row id="geography">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="department"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.department') }}</span>
                <b-form-select
                  v-model="elementData.address_department_id"
                  :options="departmentList"
                  value-field="id"
                  text-field="value"
                  disabled-field="notEnabled"
                  disabled
                  @change="onDeliveryDepartmentChange()"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="city"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.city') }}</span>
                <b-form-select
                  v-model="elementData.address_city_id"
                  :options="deliveryCityList"
                  value-field="id"
                  text-field="value"
                  disabled-field="notEnabled"
                  disabled
                  @change="onDeliveryCityChange()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="town"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.town') }}</span>
                <b-form-input
                  id="town"
                  v-model="elementData.address_town"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="population_zone"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.population_zone') }}</span>
                <b-form-select
                  v-model="elementData.address_population_zone_id"
                  :options="populationZoneList"
                  value-field="id"
                  text-field="value"
                  disabled-field="notEnabled"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="city_code"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.city_code') }}</span>
                <b-form-input
                  v-if="elementData.address_city"
                  id="city_code"
                  v-model="elementData.address_city.key"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="observations"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.observations') }}</span>
                <b-form-textarea
                  id="observations"
                  v-model="elementData.observations"
                  maxlength="250"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Información envío nevera</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="4"
              xl="4"
            >
              <b-form-group
                label-for="freezer_id"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.freezer') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.freezer')"
                  rules=""
                >
                  <b-form-input
                    id="freezer_id"
                    v-model="elementData.freezer_id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="delivery_observations"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.delivery_observations') }}</span>
                <b-form-textarea
                  id="delivery_observations"
                  v-model="elementData.delivery_observations"
                  maxlength="500"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="2"
              xl="2"
            >
              <b-form-group
                label-for="weight"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.weight') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.weight')"
                  rules=""
                >
                  <b-form-input
                    id="weight"
                    v-model="elementData.weight"
                    type="number"
                    :formatter="weightFormat"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="commercial_value"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.commercial_value') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.commercial_value')"
                  rules=""
                >
                  <b-form-input
                    id="commercial_value"
                    v-model="elementData.commercial_value"
                    type="number"
                    :formatter="valueFormat"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >
              <date-picker
                :element-required="false"
                element-field="sent_date"
                :root-element-data="elementData"
                :element-label="$t(resourcesElementName + '.object.sent_date')"
              />
            </b-col>
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="destination_guide_number"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.destination_guide_number') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.destination_guide_number')"
                  rules=""
                >
                  <b-form-input
                    id="destination_guide_number"
                    v-model="elementData.destination_guide_number"
                    maxlength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>

            <b-col
              cols="4"
              md="4"
            >
              <date-picker
                :element-required="false"
                element-field="delivery_estimated_date"
                :root-element-data="elementData"
                :element-label="$t(resourcesElementName + '.object.delivery_estimated_date')"
              />
            </b-col>

            <b-col
              cols="4"
              md="4"
            >
              <date-picker
                :element-required="false"
                element-field="return_estimated_date"
                :root-element-data="elementData"
                :element-label="$t(resourcesElementName + '.object.return_estimated_date')"
              />
            </b-col>

            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="delivery_status"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.delivery_status') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.delivery_status')"
                  rules=""
                >
                  <v-select
                    v-model="elementData.delivery_status_id"
                    :options="deliveryStatusList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card v-if="showInfo">
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">Información recepción de la muestra</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <radio-select-picker
                :element-required="false"
                element-field="reception_type"
                :root-element-data="elementData"
                :element-label="$t(resourcesElementName + '.object.reception_type')"
                :element-selection-list="receptionList"
                :rowly="true"
                :disabled="false"
              />
            </b-col>
          </b-row>
          <div v-if="elementData.reception_type==2">
            <b-row>

              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="clinical_laboratory"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.clinical_laboratory') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.clinical_laboratory')"
                    rules=""
                  >
                    <v-select
                      v-model="elementData.clinical_laboratory_id"
                      :options="clinicalLaboratoryList"
                      label="name"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row v-if="elementData.reception_type==2 || elementData.reception_type==3">
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="reception_observations"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold info_title">{{ $t(resourcesElementName + '.object.reception_observations') }}</span>
                  <b-form-textarea
                    id="reception_observations"
                    v-model="elementData.reception_observations"
                    maxlength="500"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-card>

      <div>
        <b-card>

          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">Datos recepción de nevera</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="4"
                xl="4"
              >
                <b-form-group
                  label-for="guide_number"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.guide_number') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.guide_number')"
                    rules=""
                  >
                    <b-form-input
                      id="guide_number"
                      v-model="elementData.guide_number"
                      maxlength="20"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                md="4"
              >
                <date-picker
                  :element-required="false"
                  element-field="reception_date"
                  :root-element-data="elementData"
                  :element-label="$t(resourcesElementName + '.object.reception_date')"
                />
              </b-col>

              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="transport_company"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.transport_company') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.transport_company')"
                    rules=""
                  >
                    <v-select
                      v-model="elementData.transport_company_id"
                      :options="transporterList"
                      label="name"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>

              <b-col
                cols="4"
                xl="4"
              >
                <b-form-group
                  label-for="commercial_value_returned"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.commercial_value_returned') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.commercial_value_returned')"
                    rules=""
                  >
                    <b-form-input
                      id="commercial_value_returned"
                      v-model="elementData.commercial_value_returned"
                      type="number"
                      :formatter="valueFormat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="sample_status"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.sample_status') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.sample_status')"
                    rules=""
                  >
                    <v-select
                      v-model="elementData.sample_status_id"
                      :options="sampleStatusList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="freezer_status"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.freezer_status') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.freezer_status')"
                    rules=""
                  >
                    <v-select
                      v-model="elementData.freezer_status_id"
                      :options="freezerStatusList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">Seguimiento</span>
            </div>
          </b-card-header>
          <b-card-body>

            <b-row>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="delivery_status"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.delivery_status') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.delivery_status')"
                    rules=""
                  >
                    <v-select
                      v-model="elementData.delivery_status_id"
                      :options="deliveryStatusList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="delivery_status"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.reception_status') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.reception_status')"
                    rules=""
                  >
                    <v-select
                      v-model="elementData.reception_status_id"
                      :options="receptionStatusList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="next_action"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.next_action') }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.next_action')"
                    rules=""
                  >
                    <v-select
                      v-model="elementData.next_action_id"
                      :options="nextActionList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid"
        >
          {{ $t('actions.save') }}
        </b-button>
        <b-button
          v-if="reception_status_key(elementData.reception_status_id) !== ''"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid"
          @click="request_auth"
        >
          {{ $t('actions.request_auth') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>

</template>

<script>
import {
  BFormTextarea, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import RadioSelectPicker from '@/views/core/RadioSelectPicker.vue'
import moment from 'moment'
import router from '@/router'
import vSelect from 'vue-select'
import PatientEditCard from './EditPatientCard.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    RadioSelectPicker, PatientEditCard, BFormTextarea, DatePicker, BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver, vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    nextActionList: {
      type: Array,
      required: true,
    },
    deliveryStatusList: {
      type: Array,
      required: true,
    },
    receptionStatusList: {
      type: Array,
      required: true,
    },
    freezerStatusList: {
      type: Array,
      required: true,
    },
    sampleStatusList: {
      type: Array,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    transporterList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    cityList: {
      type: Array,
      required: true,
    },
    deliveryCityList: {
      type: Array,
      required: true,
    },
    relationshipList: {
      type: Array,
      required: true,
    },
    clinicalLaboratoryList: {
      type: Array,
      required: true,
    },
    populationZoneList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resourcesElementName: 'freezer',
      vigence_date_render: '',
      required,
      receptionList: undefined,
      showInfo: false,
    }
  },
  watch: {
    'elementData.delivery_estimated_date': function () {
      this.elementData.return_estimated_date = moment(this.elementData.delivery_estimated_date).add(8, 'days').toDate()
    },
    'elementData.return_estimated_date': function () {
      if (moment(this.elementData.return_estimated_date).isBefore(moment(), 'day') && !this.elementData.delivery_status_id) {
        const sentPendingStatus = this.deliveryStatusList.find(element => element.key === 'SENT_PENDING')
        this.elementData.delivery_status_id = sentPendingStatus ? sentPendingStatus.id : null
      }
    },
  },
  mounted() {
    this.receptionList = [
      { id: '1', value: 'Recepción Avenir' },
      { id: '2', value: 'Toma en laboratorio y recepción en Avenir' },
      { id: '3', value: 'La muestra no se recibe en Avenir' },
    ]
    if (!this.elementData.reception_type) {
      this.elementData.reception_type = 1
    }
    this.$root.$emit('fetch-delivery-cities', this.elementData.address_department_id)
  },
  methods: {
    reception_status_key(id) {
      const ret = this.receptionStatusList.find(element => element.id === id)
      return (ret?.id > 0) ? ret.key : ''
    },
    router() {
      return router
    },
    weightFormat(value) {
      const ret = parseInt(value).toLocaleString('en-US', {
        useGrouping: false,
      })
      return ret > 999 ? 999 : ret
    },
    valueFormat(value) {
      const ret = parseInt(value).toLocaleString('en-US', {
        useGrouping: false,
      })
      return ret > 999999 ? 999999 : ret
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          this.$parent.save(this.elementData)
        }
      })
    },
    request_auth() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          this.$parent.request_auth(this.elementData)
        }
      })
    },
    onDeliveryDepartmentChange() {
      this.elementData.address_city_id = null
      this.$root.$emit('fetch-delivery-cities', this.elementData.address_department_id)
    },
    onDeliveryCityChange() {
      this.elementData.address_city = this.deliveryCityList.find(element => element.id == this.elementData.address_city_id)
    },
  },
}
</script>

<style>

</style>
